import Vue from "vue";
import Vuex from "vuex";
import auth from "../controller/auth";
import router from "@/router";
import employee from '@/controller/employee'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {},
    alert: {
      snackbar: false,
      text: "",
      color: "",
    },
    employee: [],
  },
  getters: {
    alerts(state) {
      return state.alert;
    },
    employee(state){
      return state.employee;
    },
    user(state){
      return state.user;
    }
  },
  mutations: {
    login(state, user) {
      state.isLoggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = {};
    },
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
    set_employee(state,employee){
      state.employee = employee;
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      // เรียก api
      let result = await auth.login({ username, password });
      commit("login", result.user);
      // set data to localstorage
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("token", result.token);
      router.push("/");
    },

    async adminLogin({ commit }, { username, password }) {
      // เรียก api
      let result = await auth.adminLogin({ username, password });
      commit("login", result.user);
      // set data to localstorage
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("token", result.token);
      router.push("/admin");
    },

    logout({ commit }) {
      // call mutation logout
      commit("logout");
      // remove localstorage auth
      localStorage.removeItem("user");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
    },
    openAlert({ commit }, { text, color }) {
      commit("SET_ALERT", { snackbar: true, text: text, color: color });
    },
    async getEmployee({ commit,state }){
      // check state employee is empyt
      if (state.employee.length == 0) {
        let {data} = await employee.getEmployeeDp();
        commit("set_employee",data.result)
      }
      
      
    }
  },
});
