import httpClient from "../services/http-service";

const getEmployeeDp = async () => {
  return httpClient.get("employee");
};

const getEmployee = async () => {
  return httpClient.get("allEmployee");
};

const updateEmployee = async (data) => {
  return httpClient.put("employee", data);
};

export default {getEmployeeDp, getEmployee, updateEmployee};
