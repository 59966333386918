<template>
  <v-app>
    <Appbars v-if="isLoggedIn" class="menu_bar"/>
    <Alert/>
    <router-view />
  </v-app>
</template>

<script>
// navigator.geolocation.getCurrentPosition(function(position) {
//   var lat = position.coords.latitude;
//   var lon = position.coords.longitude;
//   console.log("Latitude: " + lat + ", Longitude: " + lon);
// });

// var userAgent = navigator.userAgent;
// console.log("User-Agent: " + userAgent);

document.addEventListener("orientationchange", function(event){
    switch(window.orientation) 
    {  
        case -90: case 90:
            /* Device is in landscape mode */
            break; 
        default:
            /* Device is in portrait mode */
    }
});

import Appbars from "../src/components/core/appbar.vue";
import { mapState } from 'vuex'
import Alert from '../src/components/core/snackbar.vue'
export default {
  name: "App",
  components: {
    Appbars,
    Alert
  },
  computed: {
    ...mapState(['isLoggedIn'])
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
html,body {
  font-family: 'Kanit', sans-serif;
}
#app{
  font-family: 'Kanit', sans-serif;
}


.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}


</style>
