import axios from 'axios'
import store from "@/store/index";

const baseURL = process.env.VUE_APP_BASE_URL;

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = {
        Authorization: "Bearer" + " " + userToken,
        "Content-Type": "multipart/form-data",
      };
    }
    config.url = baseURL + "/" + config.url;
  }
  config.timeout = 10000; 
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch({
      type: "openAlert",
      text: error.response.data.msg,
      color: "red",
    });
    // console.log(error.response.data.msg);
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "Cannot connect to server, Please try again.",
      });
    }

    return Promise.reject(error);
  }
);

export default axios;