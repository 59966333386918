<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="card_reader">
        <v-toolbar dark color="primary" class="fixed-bar">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> ปิด </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="viewer">
          <div class="document_A4 page-break" id="print-content">
            <!-- Header -->
            <div class="reader_header">
              <v-row>
                <v-col sm="4" md="4" xl="4"
                  ><center>
                    <img src="@/assets/takeco.png" alt="takeco" width="100px" />
                    <p>Takec Engineering Thailand</p>
                    <br /></center
                ></v-col>
                <v-col sm="4" md="4" xl="4" class="title_reader">
                  <h3 v-if="!showEdit">{{ title }}</h3>
                  <v-text-field
                    v-if="showEdit"
                    v-model="title"
                    label="หัวข้อ"
                    placeholder="Placeholder"
                  ></v-text-field>
                </v-col>
                <v-col
                  sm="4"
                  md="4"
                  xl="4"
                  class="title_reader"
                  v-if="data !== null"
                >
                  <div class="text_item">
                    <div class="textitem_row">
                      <strong>วันที่ : </strong>
                      <p>{{ dayjs(data.start).format("DD/MM/YYYY") }}</p>
                    </div>
                    <div class="textitem_row">
                      <strong>ผู้เขียน : </strong>
                      <p>{{ data.Author }}</p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <br />
            <div v-if="data !== null" class="html_view">
              <!-- html data -->
              <div
                v-html="data.detail"
                v-if="!showEdit"
                class="html_show"
              ></div>
              <!-- Users data -->

              <div class="showUsers" v-if="!showEdit">
                <hr />
                <div>
                  <h4>ผู้เข้าร่มประชุม</h4>
                  <div class="userDisplay">
                    <span v-for="(item, index) in users" :key="item.id"
                      >{{ index + 1 }}. {{ item.full_name }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- Editor -->
              <div v-if="showEdit" class="Editor_text">
                <v-btn class="close-btn" @click="close()" icon color="red">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <vue-editor
                  id="editor"
                  class="vue_edeitor_height"
                  v-model="htmlEdit"
                  :editor-toolbar="customToolbar"
                >
                </vue-editor>

                <div class="detailEditor">
                  <!-- addUsers -->
                  <v-autocomplete
                    hide-details
                    label="เลือกผู้เข้าร่วม"
                    :items="employee"
                    item-text="name"
                    item-value="id"
                    v-model="delegate"
                    dense
                    multiple
                    rounded
                    solo
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-if="delegate.length < 2"
                        v-bind="data.attrs"
                        small
                      >
                        {{ data.item.name }}
                      </v-chip>
                      <div v-else-if="data.index === 0">
                        {{ `${delegate.length} items selected` }}
                      </div>
                    </template>
                  </v-autocomplete>

                  <!-- file -->
                  <v-file-input
                    v-if="showEdit"
                    multiple
                    label="ไฟล์เพิ่มเติม"
                    v-model="files"
                  ></v-file-input>
                </div>

                <br />
                <center>
                  <v-btn
                    rounded
                    color="primary"
                    dark
                    @click.prevent="updateDocument()"
                  >
                    อัพเดต
                  </v-btn>
                </center>
              </div>
            </div>
          </div>
        </div>

        <!-- Menu -->
        <div class="sp_btn">
          <v-speed-dial
            v-model="fab"
            bottom
            right
            direction="top"
            transition="slides-y-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="blue darken-2" dark fab>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-menu </v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="green" @click="editDocument()">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="indigo"
              @click="printDoc()"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="red"
              @click="deleteDoc()"
              v-if="rule == 'admin'"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import meeting from "@/controller/meeting";
import { mapGetters } from "vuex";

export default {
  props: ["meetingData"],
  components: {
    VueEditor,
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      title: "",
      users: null,
      data: null,
      fab: false,
      showEdit: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
      htmlEdit: "",
      files: [],
      delegate: [],
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    rule() {
      return this.$store.getters.user.rule;
    },
  },
  watch: {
    meetingData(newValue) {
      let { data, dialog, users } = newValue;
      this.dialog = dialog;
      this.title = data.title;
      this.users = users;
      this.data = data;
      this.delegate = [];
      for (let index = 0; index < users.length; index++) {
        this.delegate.push(users[index].id);
      }
    },
    dialog(newValue) {
      if (newValue == false) {
        this.showEdit = false;
      }
    },
    // delegate(newValue) {
    //   console.log(newValue)
    // }
  },
  methods: {
    getUsers() {},
    editDocument() {
      this.htmlEdit = this.data.detail;
      this.showEdit = true;
    },
    async updateDocument() {
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("htmlData", this.htmlEdit);
      formData.append("id", this.data.id);
      formData.append("users", this.delegate);

      //File
      if (this.files.length !== 0) {
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          formData.append("files[" + i + "]", file);
        }
      }

      let { data } = await meeting.updateMeetingHtml(formData);
      if (data.msg == "ok") {
        this.data.detail = this.htmlEdit;
        const back_data = {
          html: this.htmlEdit,
          id: this.data.id,
          title: this.title,
        };
        this.$emit("updateHtml", back_data);
        this.close();
      } else {
        alert(data.msg);
      }
    },
    async deleteDoc() {
      this.$swal({
        title: "คุณต้องการลบหรือไม่",
        text: "คุณจะเปลี่ยนกลับไม่ได้!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await meeting.deleteMeeting(this.data.id);
          if (data.msg == "ok") {
            this.$emit("deleteMeeting", this.data.id);
            this.dialog = false;
          } else {
            alert(data.msg);
          }
          Swal.fire("ลบแล้ว!", "ไฟล์ของคุณถูกลบแล้ว.", "สำเร็จ");
        }
      });
    },
    close() {
      this.htmlEdit = "";
      this.showEdit = false;
    },
    printDoc() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print-content").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "width=" + screen.availWidth + ",height=" + screen.availHeight
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
<head>
${stylesHtml}
</head>
<body>
${prtHtml}
</body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();

      WinPrint.focus();
      WinPrint.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    margin: 2.5cm;
    size: A4;
  }
  .page-break {
    page-break-before: always;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
}
.document_A4 {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 30px;
}
.document_A4 {
  width: 21cm;
  height: auto;
}
.viewer {
  margin: 30px 0px 0px 0px;
  padding-bottom: 20px;
}
.html_view {
  display: block;
}
.title_reader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_item {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
}
.text_item strong {
  font-size: 14px;
}
.text_item p {
  font-size: 14px;
  margin: 0;
}
.textitem_row {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
div.reader_header > div {
  height: 120px;
}
.v-btn--floating {
  position: relative;
}
.v-speed-dial {
  position: absolute;
}
.card_reader {
  position: relative;
}
.sp_btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
}
.Editor_text {
  position: relative;
}
.close-btn {
  position: absolute;
  right: 0;
}
.userDisplay {
  display: flex;
  flex-direction: column;
}
.showUsers hr {
  margin-top: 20px;
  margin-bottom: 10px;
}
.detailEditor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .detailEditor {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    margin-top: 10px;
  }
  div.reader_header > div {
    height: 100px;
  }

  .sp_btn {
    position: fixed;
    right: 15px;
    bottom: 15px;
  }

  .viewer {
    margin: 15px 0px 0px 0px;
    padding-bottom: 10px;
    width: 100%;
  }
  .document_A4 {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    padding: 30px;
  }
  .document_A4 {
    width: fit-content;
    max-width: 100%;
    height: auto;
    padding: auto;
  }
  #print-content > div.reader_header > div > div:nth-child(1) > center > img {
    width: 50px;
  }
  #print-content > div.reader_header > div > div:nth-child(1) > center > p {
    font-size: 10px;
  }
  #print-content > div.reader_header > div > div:nth-child(2) > h3 {
    font-size: 14px;
  }
  .text_item strong {
    font-size: 10px;
  }
  .text_item p {
    font-size: 10px;
    margin: 0;
  }
  .html_show {
    font-size: 12px;
  }
  .showUsers h4 {
    font-size: 12px;
  }
  .showUsers span {
    font-size: 12px;
  }
  .showUsers hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
