import httpClient from "../services/http-service";


const isLoggedIn = () => {
  let token = localStorage.getItem("isLoggedIn");
  // console.log(token)
  return token != null;
};

const login = async (data) => {
  let formData = new FormData();
  formData.append("username", data.username);
  formData.append("password", data.password);
  let result = await httpClient.post("login", formData);
  if (result.statusText == "OK") {
    return result.data
  }
};


const adminLogin = async (data) => {
  let formData = new FormData();
  formData.append("username", data.username);
  formData.append("password", data.password);
  let result = await httpClient.post("adminlogin", formData);
  if (result.statusText == "OK") {
    return result.data
  }
};

const updatePassword = async (form) => {
  let {data} = await httpClient.put("password", form);
    return data
};


export default {
  login,isLoggedIn,
  adminLogin,
  updatePassword
};
