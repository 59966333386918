<template>
  <div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              วันนี้
            </v-btn>
            <v-spacer></v-spacer>
            <!-- desktop menu -->
            <div class="desktop_date">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    class="text-field-width"
                    readonly
                    outlined
                    dense
                    rounded
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <!-- Mobile Menu -->
            <div class="Mobile_menu">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :close-on-content-click="false"
                :return-value.sync="date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    class="text-field-width"
                    readonly
                    outlined
                    dense
                    rounded
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                >
                </v-date-picker>
              </v-dialog>
            </div>

            <v-spacer></v-spacer>
            <v-menu bottom right class="menu_select_month">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            locale="th"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              class="card_showDetail"
              max-width="400px"
              flat
            >
              <v-toolbar
                :color="
                  rule == 'admin' ? selectedEvent.dp_color : selectedEvent.color
                "
                dark
              >
                <!-- <v-btn icon @click="editData(selectedEvent.id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn> -->
                <v-toolbar-title v-if="rule == 'admin'"
                  >{{ selectedEvent.name }} ( แผนก
                  {{ selectedEvent.dp_name }} )</v-toolbar-title
                >
                <v-toolbar-title v-else>{{
                  selectedEvent.name
                }}</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon @click="viewData(selectedEvent.id)">
                  <v-icon :small="$vuetify.breakpoint.smAndDown"
                    >mdi-page-next-outline</v-icon
                  >
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <p>ผู้เข้าร่วมประชุม</p>
                <div>
                  <!-- chip -->
                  <div v-if="users_data.length <= 4">
                    <v-chip
                      v-for="item in users_data"
                      :key="item.id"
                      class="ma-2"
                      dark
                      :small="$vuetify.breakpoint.smAndDown"
                      :color="item.color"
                    >
                      {{ item.full_name.split(" ")[0] }}
                    </v-chip>
                  </div>
                  <!-- chip if item > 4 -->
                  <div v-else class="user_show">
                    <v-chip
                      v-for="item in users_data.slice(0, 4)"
                      :key="item.id"
                      class="ma-2"
                      dark
                      :color="item.color"
                    >
                      {{ item.full_name.split(" ")[0] }}
                    </v-chip>
                    <!-- click more user -->
                    <p @click="showUser = true" class="more_user">
                      และอีก
                      <strong>{{ users_data.length - 4 }} คน</strong>
                      ที่เข้าร่วมประชุม
                    </p>
                  </div>

                  <div class="detail_body">
                    <p>รายละเอียด</p>
                    <v-row>
                      <v-col sm="12" md="6" xl="6" class="detail_calendar">
                        <strong>เริ่มต้น : </strong>
                        <p>
                          {{ dayjs(selectedEvent.start).format("HH:mm:ss") }} น.
                        </p>
                      </v-col>
                      <v-col sm="12" md="6" xl="6" class="detail_calendar">
                        <strong>สิ้นสุด : </strong>
                        <p>
                          {{ dayjs(selectedEvent.end).format("HH:mm:ss") }} น.
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                  <div
                    class="detail_calendar"
                    v-if="this.$store.getters.user.rule == 'admin'"
                  >
                    <strong>แผนก : </strong>
                    <p>
                      {{ selectedEvent.dp_name }}
                    </p>
                  </div>

                  <div class="file_detail">
                    <hr />
                    <v-tooltip bottom v-for="item in files" :key="item.id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          class="ma-2 file_element"
                          dark
                          small
                          color="primary"
                          @click="download(item)"
                        >
                          <div>
                            <v-icon v-if="item.type.startsWith('image/')"
                              >mdi-image-area</v-icon
                            >
                            <v-icon v-if="item.type == 'application/pdf'"
                              >mdi-file-pdf-box</v-icon
                            >
                            <v-icon
                              v-if="item.type.startsWith('application/vnd')"
                              >mdi-file-word</v-icon
                            >
                          </div>

                          {{ item.name | truncate(10) }}
                        </v-chip>
                      </template>
                      <span>{{ item.size | bytes }}</span>
                    </v-tooltip>
                  </div>
                  <div v-if="imageUrl.length !== 0">
                    <img :src="imageUrl" width="50%" />
                  </div>
                </div>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  ปิด
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>

    <div class="MeetingTable">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">วันที่</th>
              <th class="text-left">หัวข้อ</th>
              <th>สถานที่</th>
              <th>แผนก</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in meetingDatabase" :key="item.index">
              <td>{{ dayjs(item.start).format("DD/MM/YYYY") }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.location }}</td>
              <td>
                <v-chip :small="$vuetify.breakpoint.smAndDown" dark :color="item.dpcolor">{{ item.name }}</v-chip>
              </td>
              <td>
                <v-btn icon color="pink" @click="viewData(item.id)">
                  <v-icon  :small="$vuetify.breakpoint.smAndDown">mdi-page-next-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- <div class="tableView">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Calories
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in desserts"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.calories }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div> -->

    <Adddata
      :dialogs="dialog"
      @setDialog="setDialog"
      :date="focus"
      @setEvent="setEvent"
    />

    <Reader
      :meetingData="ReaderData"
      @updateHtml="htmlUpdate"
      @deleteMeeting="delete_Meeting"
    />

    <v-dialog v-model="showUser" width="500">
      <v-card>
        <v-card-title class="text-h5 primary lighten-2">
          ผู้เข้าร่วมประชุม
        </v-card-title>

        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ชื่อ-นามสกุล</th>
                  <th class="text-left">แผนก</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in users_data" :key="item.name">
                  <td>{{ item.full_name }}</td>
                  <td>
                    <v-chip small dark :color="item.color">
                      {{ item.department }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showUser = false"> ปิด </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Adddata from "@/components/carlendar/add.vue";
import meeting from "@/controller/meeting";
import Reader from "@/components/carlendar/read";
import axios from "axios";

export default {
  name: "Carlendar",
  components: {
    Adddata,
    Reader,
  },
  data: () => ({
    ReaderData: {
      dialog: false,
      users: null,
      data: null,
    },

    dialog: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    event_data: [],
    users_data: [],
    showUser: false,
    modal: false,
    files: [],
    imageUrl: "",
    meetingDatabase: [],
  }),
  computed: {
    rule() {
      return this.$store.getters.user.rule;
    },
  },
  watch: {
    date(newValue) {
      let date = newValue + "-01";
      this.focus = date;
      this.events = [];
      this.getData(newValue);
      this.$refs.menu.save(newValue);
      this.$refs.dialog.save(newValue);
      this.menu = false;
      this.modal = false;
    },
  },
  mounted() {
    let today = new Date();
    let date = today.toISOString().slice(0, 7);
    this.getData(date);
  },

  beforeDestroy() {
    URL.revokeObjectURL(this.imageUrl);
  },

  methods: {
    async getData(date) {
      let formData = new FormData();
      formData.append("date", date);
      formData.append("rule", this.$store.getters.user.rule);
      formData.append("id", JSON.parse(localStorage.getItem("user")).id);

      let { data } = await meeting.getMeeting(formData);
      if (data.msg == "ok") {
        this.event_data = data.result;
        let event = [];
        data.result.forEach((el) => {
          event.push({
            id: el.id,
            name: el.title,
            detail: el.detail,
            start: this.convertTime(el.start),
            end: this.convertTime(el.end),
            color: el.color,
            timed: true,
            dp_name: el.name,
            dp_color: el.dp_color,
          });
        });
        this.events = event;
      }
    },
    // editData(id) {
    //   const element = this.event_data.find((obj) => obj.id === id);
    //   console.log(element);
    // },
    convertTime(dateTime) {
      let date_object = new Date(dateTime);
      return date_object;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "month";
      this.dialog = true;
    },
    getEventColor(event) {
      if (this.$store.getters.user.rule == "admin") {
        return event.dp_color;
      } else {
        return event.color;
      }
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
      console.log(this.$refs.calendar);
    },
    next() {
      this.$refs.calendar.next();
      console.log(this.$refs.calendar);
    },
    async showEvent({ nativeEvent, event }) {
      this.imageUrl = "";
      let { data } = await meeting.get_users(event.id);
      if (data.msg == "ok") {
        this.users_data = data.result;
        this.files = data.files;
      } else {
        alert(data.msg);
      }

      const open = async () => {
        this.selectedEvent = event;

        let formData = new FormData();
        formData.append("date", this.dayjs(event.start).format("YYYY-MM-DD"));

        let { data } = await meeting.getMeetingBydate(formData);
        console.log(data.result);
        this.meetingDatabase = data.result;

        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;

        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    setDialog() {
      this.dialog = false;
    },

    async setEvent(event) {
      this.events.push({
        id: event.id,
        name: event.title,
        start: event.dateStart,
        end: event.dateEnd,
        color: event.color,
        timed: true,
        dp_name: event.dp_name,
        dp_color: event.dp_color,
      });
      let { data } = await meeting.getOneMeeting(event.id);
      if (data.msg == "ok") {
        this.event_data.push(data.result[0]);
      } else {
        alert(data.msg);
      }
    },

    viewData(id) {
      const element = this.event_data.find((obj) => obj.id === id);
      this.ReaderData = {
        dialog: true,
        users: this.users_data,
        data: element,
      };
    },

    htmlUpdate(data) {
      let obj = this.event_data.findIndex((obj) => obj.id == data.id);
      this.event_data[obj].detail = data.html;
      this.event_data[obj].title = data.title;

      let upd_obj = this.events.findIndex((obj) => obj.id == data.id);
      this.events[upd_obj].name = data.title;

      this.getMeetingDetail(data.id);
    },
    async getMeetingDetail(id) {
      let { data } = await meeting.get_users(id);
      if (data.msg == "ok") {
        this.users_data = data.result;
        this.files = data.files;
      } else {
        alert(data.msg);
      }
    },

    delete_Meeting(id) {
      this.events.splice(
        this.events.findIndex((obj) => obj.id == id),
        1
      );
      this.event_data.splice(
        this.event_data.findIndex((obj) => obj.id == id),
        1
      );
    },

    download({ name }) {
      const userToken = localStorage.getItem("token");
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/download/file/${name}`, {
          headers: {
            Authorization: "Bearer" + " " + userToken,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.data.type.startsWith("image/")) {
            if (this.imageUrl.length !== 0) {
              this.imageUrl = "";
            } else {
              const blob = new Blob([response.data], { type: "image/png" });
              this.imageUrl = URL.createObjectURL(blob);
            }
          } else {
            const blob = new Blob([response.data]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = name;
            link.click();
            URL.revokeObjectURL(link.href);
          }
        })
        .catch(console.error);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field-width {
  width: 120px;
}
.Mobile_menu {
  display: none;
}
.v-application p {
  margin-bottom: 0px;
}
.detail_calendar {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.detail_body p {
  margin-bottom: 5px;
}
.more_user {
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 300ms ease;
  color: #0b75cc;
}
.more_user:hover {
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  color: red;
}

.user_show {
  margin-bottom: 5px;
}
.card_showDetail {
  min-width: 350px;
}
.file_detail {
  margin-top: 5px;
}
.file_element {
  cursor: pointer;
}

.MeetingTable {
  margin-top: 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
  .text-field-width {
    width: 70px;
    font-size: 12px;
  }
  .detail_calendar {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }
  button:nth-child(7) {
    font-size: 12px;
  }
  button.mr-4.v-btn.v-btn--outlined.theme--light.v-size--default.grey--text.text--darken-2 {
    font-size: 12px;
  }
  .desktop_date {
    display: none;
  }
  .Mobile_menu {
    display: contents;
  }
  .v-toolbar__title {
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .card_showDetail {
    min-width: 300px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 12px;
    height: 48px;
}
}
</style>
