<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark
          ><h3>รายชื่อพนักงาน</h3>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="$emit('setDialog', false)">
            <v-icon>mdi-alpha-x-circle</v-icon>
          </v-btn></v-toolbar
        >
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th class="text-left">ชื่อ</th>
                  <th class="text-left">ชื่อเล่น</th>
                  <th class="text-left">แผนก</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in employees" :key="item.index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <p>{{ item.firstname }} {{ item.lastname }}</p>
                  </td>
                  <td>
                    <p>{{ item.nick_name }}</p>
                  </td>
                  <td>
                    <v-chip dark :color="item.color">{{
                      item.department
                    }}</v-chip>
                  </td>
                  <td>
                    <v-btn icon color="primary" @click="openEditor(item)">
                      <v-icon>mdi-book-edit</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="$emit('setDialog', false)">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Editor -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="editor"
    >
      <template v-slot:default="editor">
        <v-card>
          <v-toolbar color="primary" dark>แก้ไขพนักงาน</v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent="update()">
              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="employeeEdit.firstname"
                    :rules="rules.name"
                    color="purple darken-2"
                    label="ชื่อ"
                    required
                  ></v-text-field
                ></v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="employeeEdit.lastname"
                    :rules="rules.name"
                    color="purple darken-2"
                    label="นามสกุล"
                    required
                  ></v-text-field
                ></v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="employeeEdit.nick_name"
                    :rules="rules.name"
                    color="purple darken-2"
                    label="ชื่อเล่น"
                    required
                  ></v-text-field
                ></v-col>
                <v-col md="6" sm="12">
                  <v-autocomplete
                    v-model="employeeEdit.dpid"
                    :items="department"
                    color="blue-grey lighten-2"
                    label="แผนก"
                    item-text="name"
                    item-value="id"
                    required
                    :rules="rules.name"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text color="red" @click="editor.value = false">ปิด</v-btn>
            <v-btn
              :disabled="!formIsValid"
              text
              color="success"
              @click="update()"
              >อัพเดต</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MP from "@/controller/employee";
import depart from "@/controller/department";
export default {
  props: ["dialog"],
  data() {
    return {
      employees: [],
      employeeEdit: null,
      editor: false,
      rules: {
        name: [(val) => (val || "").length > 0 || "ต้องกรอกข้อมูลช่องนี้"],
      },
      department: [],
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    formIsValid() {
      return (
        this.employeeEdit.firstname &&
        this.employeeEdit.lastname &&
        this.employeeEdit.nick_name
      );
    },
  },
  watch: {},
  created() {
    this.getEmployee();
    this.getDepart();
  },
  methods: {
    async getEmployee() {
      const { data } = await MP.getEmployee();
      this.employees = data.result;
      this.employees.sort((a, b) => a.department.localeCompare(b.department))
    },
    openEditor(el) {
      this.employeeEdit = el;
      this.editor = true;
    },
    async getDepart() {
      const { data } = await depart.getdepartment();
      this.department = data.result;
    },
    async update() {
      let formData = new FormData();
      formData.append("firstname", this.employeeEdit.firstname);
      formData.append("lastname", this.employeeEdit.lastname);
      formData.append("nickname", this.employeeEdit.nick_name);
      formData.append("department", this.employeeEdit.dpid);
      formData.append("id", this.employeeEdit.id);


      const { data } = await MP.updateEmployee(formData);
      if (data.msg == "ok") {
      
      const departIndex = this.department.findIndex((obj) => obj.id == this.employeeEdit.dpid)

      let upd_obj = this.employees.findIndex((obj) => obj.id == this.employeeEdit.id);
      this.employees[upd_obj].dpid = this.employeeEdit.dpid;
      this.employees[upd_obj].department = this.department[departIndex].name;
      this.employees[upd_obj].color = this.department[departIndex].color;

      this.employees.sort((a, b) => a.department.localeCompare(b.department))

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "อัพเดตสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        this.editor = false;
      } else {
        alert(data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.v-dialog > .v-card > .v-card__text {
  margin-top: 30px;
}
</style>
