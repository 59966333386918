<template>
  <div>
    <v-row>
      <v-col md="6" sm="12" class="left-content">
        <img :src="require('@/assets/login.svg')" alt="login" />
        <h1>MEETING SYSTEM</h1>
      </v-col>
      <v-col md="6" sm="12" class="rigth-content">
        <img :src="require('@/assets/user.png')" alt="Users" />
        <h3>Welcome</h3>
        <br />
        <div>
          <!-- Username -->
          <v-text-field
            label="Username"
            filled
            class="text_auth"
            v-model="username"
          ></v-text-field>
          <!-- Password -->
          <v-text-field
            label="Password"
            filled
            class="text_auth"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            v-model="password"
            @keydown.enter="submit"
          ></v-text-field>
          <!-- Forgot Password -->
          <p class="forgot" @click="forgot()">ลืมรหัสผ่าน !</p>
        </div>
        <!-- btn login -->
        <div class="text-center loginbt">
          <v-btn
            rounded
            color="#1888E6"
            dark
            :large="$vuetify.breakpoint.mdAndUp"
            @click="submit"
          >
            <v-icon color="white darken-2" class="icon_login">
              mdi-login
            </v-icon>
            Login
          </v-btn>
        </div>
        <div class="admin-login">
          <v-btn rounded color="#C4C4C4" dark @click="AdminLogin()" small text>
            Admin Login
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      show: false,
      username: "",
      password: "",
    };
  },
  created() {
    if (localStorage.getItem("isLoggedIn")) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    ...mapActions(["login", "adminLogin"]),
    async submit() {
      if (!this.username || !this.password) return;
      try {
        const user = await this.login({
          username: this.username,
          password: this.password,
        });
      } catch (error) {
        // console.log(error);
      }
    },
    forgot() {
      const imagePath = require("@/assets/qrcode.jpg");
      this.$swal({
        title: "ลืมรหัสผ่าน!",
        text: "ติดต่อได้ที่ LineID : bqas หรือโทร 062-309-8133",
        imageUrl: imagePath,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "lineID",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#f9a826",
      });
    },
    async AdminLogin() {
      if (!this.username || !this.password) return;
      try {
        const user = await this.adminLogin({
          username: this.username,
          password: this.password,
        });
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
body {
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  filter: drop-shadow(0 0 10px white);
}
.left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1888e6;

  margin: 0;
  height: 100vh;
  width: 100vw;
}

.left-content h1 {
  color: #ffffff;
}

.rigth-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
}

.rigth-content h3 {
  color: #1888e6;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.rigth-content img {
  width: 80px;
  height: 80px;
}

.left-content img {
  width: 500px;
}

div.rigth-content.col-sm-3.col-md-6.col > div:nth-child(4) > div:nth-child(1) {
  border-radius: 30px;
}

div.rigth-content.col-sm-3.col-md-6.col > div:nth-child(4) > div:nth-child(2) {
  border-radius: 30px;
}

.v-text-field > .v-input__slot::before {
  border-style: none !important;
}

.v-text-field {
  width: 400px;
}

.forgot {
  color: #8e8e8e;
  cursor: pointer;
  text-align: right;
  font-size: 12px;
}

.forgot:hover {
  color: #f9a826;
}

.v-btn {
  transition: all 500ms ease;
  width: 100px;
  min-width: 100px;
}

.v-btn:hover {
  width: 150px;
  min-width: 200px;
}
.admin-login {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .v-btn.v-size--default {
    font-size: 12px;
  }
  .forgot {
    color: #8e8e8e;
    cursor: pointer;
    text-align: right;
    font-size: 12px;
    margin-right: 20px;
  }
  .text_auth{
    width: 300px;
  }
  .left-content {
    display: none;
  }
}
</style>
