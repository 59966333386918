import httpClient from "../services/http-service";

const insertMeeting = async (data) => {
  return httpClient.post("meeting", data);
};

const getMeeting = async (data) => {
  return httpClient.post("getMeeting", data);
};

const uploadPicture = async (data) => {
  return httpClient.post("uploadPicture", data);
};

const get_users = (id) => {
  return httpClient.get("users" + `/meeting/${id}`);
};

const getOneMeeting = (id) => {
  return httpClient.get("meeting" + `/id/${id}`);
};

const updateMeetingHtml = (data) => {
  return httpClient.put("meetingHtml", data);
};

const deleteMeeting = (id) => {
  return httpClient.delete("meeting" + `/id/${id}`);
};

const getMeetingBydate = async (data) => {
  return httpClient.post("getMeetingDate", data);
};


export default {
  insertMeeting,
  getMeeting,
  uploadPicture,
  get_users,
  getOneMeeting,
  updateMeetingHtml,
  deleteMeeting,
  getMeetingBydate
};
