<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1500">
      <!-- Header -->
      <div class="form_data">
        <div class="exit-form">
          <v-btn icon color="red" x-small @click="dialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
        <div class="header-card">
          <v-row>
            <v-col sm="12" md="6" xl="6" class="ml-10">
              <h2>เพิ่มการประชุม</h2>
            </v-col>
            <v-col sm="12" md="6" xl="6" class="rigth-header">
              <div class="date-show">
                <p class="title_name">วันที่</p>
                <p class="detail_name">
                  {{ dayjs(form.date).format("DD/MM/BBBB") }}
                </p>
                <p class="title_name">เวลา</p>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.time_start"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.time_start"
                      label="เวลาเริ่ม"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu"
                    v-model="form.time_start"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu.save(form.time_start)"
                  ></v-time-picker>
                </v-menu>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.time_end"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.time_end"
                      label="เวลาสิ้นสุด"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="form.time_end"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu2.save(form.time_end)"
                  ></v-time-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Form Body Top-->
        <div class="body-form">
          <v-row v-if="rule !== 'admin'" class="mobile_flex">
            <v-col sm="12" md="4" xl="4">
              <div class="form-body">
                <p class="title_name">หัวข้อการประชุม</p>
                <v-text-field
                  v-model="form.title"
                  dense
                  hide-details
                  placeholder="หัวข้อการประชุม"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
            <v-col sm="12" md="4" xl="4">
              <div class="form-body">
                <p class="title_name">ผู้ร่วมประชุม</p>
                <v-autocomplete
                  hide-details
                  label="เลือกผู้เข้าร่วม"
                  deletable-chips
                  :items="employee"
                  item-text="name"
                  item-value="id"
                  v-model="form.delegate"
                  dense
                  multiple
                  rounded
                  small-chips
                  solo
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name.split(" ")[1] }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
            <v-col sm="12" md="4" xl="4">
              <div class="form-body">
                <p class="title_name">ผู้เขียน</p>
                <v-text-field
                  v-model="form.author"
                  dense
                  hide-details
                  placeholder="ผู้เขียนบันทึกนี้"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">สถานที่</p>
                <v-text-field
                  v-model="form.location"
                  dense
                  hide-details
                  placeholder="สถานที่ประชุม"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row v-else class="mobile_flex">
            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">หัวข้อการประชุม</p>
                <v-text-field
                  v-model="form.title"
                  dense
                  hide-details
                  placeholder="หัวข้อการประชุม"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">ผู้ร่วมประชุม</p>
                <v-autocomplete
                  hide-details
                  label="เลือกผู้เข้าร่วม"
                  :items="employee"
                  item-text="name"
                  item-value="id"
                  v-model="form.delegate"
                  dense
                  multiple
                  rounded
                  solo
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="form.delegate.length < 2"
                      v-bind="data.attrs"
                      small
                    >
                      {{ data.item.name }}
                    </v-chip>
                    <div v-else-if="data.index === 0">
                      {{ `${form.delegate.length} items selected` }}
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>

            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">แผนก</p>
                <v-autocomplete
                  hide-details
                  label="เลือกแผนก"
                  :items="department"
                  item-text="name"
                  item-value="id"
                  v-model="form.department"
                  dense
                  rounded
                  solo
                >
                </v-autocomplete>
              </div>
            </v-col>
            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">ผู้เขียน</p>
                <v-text-field
                  v-model="form.author"
                  dense
                  hide-details
                  placeholder="ผู้เขียนบันทึกนี้"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
            <v-col sm="12" md="3" xl="3">
              <div class="form-body">
                <p class="title_name">สถานที่</p>
                <v-text-field
                  v-model="form.location"
                  dense
                  hide-details
                  placeholder="สถานที่ประชุม"
                  solo
                  rounded
                ></v-text-field>
              </div>
            </v-col>
            
          </v-row>
          <!-- Body Center -->
          <v-row class="form_center">
            <p class="title_name">เนื้อหาการประชุม</p>
            <br />
            <vue-editor
              id="editor"
              class="vue_edeitor_height"
              use-custom-image-handler
              @image-added="handleImageAdded"
              v-model="form.detail"
              :editor-toolbar="customToolbar"
            >
            </vue-editor>
          </v-row>
          <div class="other_form">
            <v-row class="mobile_flex">
              <v-col sm="12" md="6" xl="6">
                <div class="form-body">
                  <p class="title_name">ไฟล์เพิ่มเติม</p>
                  <v-file-input
                    label="เพิ่มไฟล์การประชุม"
                    outlined
                    v-model="form.files"
                    dense
                    hide-details
                    multiple
                  ></v-file-input>
                </div>
              </v-col>

              <v-col sm="12" md="6" xl="6">
                <div class="form-body">
                  <p class="title_name">ประชุมครั้งถัดไป</p>
                  <v-datetime-picker label="เลือกวันที่" v-model="form.booking">
                  </v-datetime-picker>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <div class="buttom-form">
              <center>
                <v-btn
                  width="50px"
                  rounded
                  color="#1888E6"
                  dark
                  @click="save_data()"
                >
                  บันทึก
                </v-btn>
              </center>
            </div>
          </v-row>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import meeting from "@/controller/meeting";
import { VueEditor } from "vue2-editor";
import department from "@/controller/department";

export default {
  props: ["dialogs", "date"],
  components: {
    VueEditor,
  },
  data() {
    return {
      dialog: false,
      menu: false,
      menu2: false,
      form: {
        date: "",
        time_start: null,
        time_end: null,
        title: "",
        delegate: [],
        author: "",
        detail: "",
        files: [],
        booking: "",
        department: "",
        location:""
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
      department: [],
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    rule() {
      return this.$store.getters.user.rule;
    },
  },
  watch: {
    dialogs(newValue) {
      this.form.date = this.date;
      this.dialog = newValue;
    },
    dialog(newValue) {
      if (newValue !== true) {
        this.clear();
        this.$emit("setDialog", newValue);
      }
    },
    "form.time_start"(newVal) {
      if (this.form.time_end !== null && this.form.time_start !== null) {
        let result = this.compareTimes(newVal, this.form.time_end);
        if (result == true) {
          this.form.time_start = null;
          this.$swal({
            icon: "error",
            title: "ผิดพลาด",
            text: "กรุณาใส่เวลาให้ถูกต้อง",
            buttonColor: "#f9a826",
          });
        }
      }
    },
    "form.time_end"(newVal) {
      if (this.form.time_start !== null && this.form.time_end !== null) {
        let result = this.compareTimes(this.form.time_start, newVal);
        if (result == true) {
          this.form.time_end = null;
          this.$swal({
            icon: "error",
            title: "ผิดพลาด",
            text: "กรุณาใส่เวลาให้ถูกต้อง",
            buttonColor: "#f9a826",
          });
        }
      }
    },
    "form.booking"(newVal) {
      if (this.form.booking !== "") {
        if (this.isLessThan(newVal,this.date) == true) {
          this.form.booking = ""
          this.$swal({
            icon: "error",
            title: "ผิดพลาด",
            text: "กรุณาใส่เวลาให้ถูกต้อง",
            buttonColor: "#f9a826",
          });
        }
      }
    },
  },
  created() {
    if (this.$store.getters.user.rule == "admin") {
      this.getDepartment();
    }
  },
  methods: {
    isLessThan(dateA, dateB) {
      let dateObjectA = new Date(dateA);
  let dateObjectB = new Date(dateB);
  return dateObjectA < dateObjectB && dateObjectA !== dateObjectB;
    },
    async save_data() {
      //   console.log(this.convertTime(this.form.date,this.form.time_start));
      this.form.dateStart = this.convertTime(
        this.form.date,
        this.form.time_start
      );
      this.form.start = this.convertTime(this.form.date, this.form.time_start);
      this.form.dateEnd = this.convertTime(this.form.date, this.form.time_end);
      this.form.color = this.getRandomColor();
      if (this.validateForm(this.form)) {
        let formData = new FormData();

        //Form
        formData.append("start", this.form.start);
        formData.append("end", this.form.dateEnd);
        formData.append("color", this.form.color);
        formData.append("title", this.form.title);
        formData.append("detail", this.form.detail);
        formData.append("author", this.form.author);
        formData.append("delegate", this.form.delegate);
        formData.append("booking", this.form.booking);
        formData.append("location", this.form.location);

        if (this.$store.getters.user.rule == "admin") {
          formData.append("department", this.form.department);
        } else {
          formData.append(
            "department",
            JSON.parse(localStorage.getItem("user")).id
          );
        }

        //File
        if (this.form.files.length !== 0) {
          for (let i = 0; i < this.form.files.length; i++) {
            let file = this.form.files[i];
            formData.append("files[" + i + "]", file);
          }
        }

        let { data } = await meeting.insertMeeting(formData);
        if (data.msg == "ok") {
          this.form.id = data.result;

          if (this.$store.getters.user.rule == "admin") {
            const el = this.department.find(
              (obj) => obj.id === this.form.department
            );

            this.form.dp_name = el.name;
            this.form.dp_color = el.color;
          } else {
            this.form.dp_name = JSON.parse(localStorage.getItem("user")).name;
            this.form.dp_color = JSON.parse(localStorage.getItem("user")).name;
          }

          this.$emit("setEvent", this.form);
          this.dialog = false;
          this.clear();
        }
        // submit the form or perform other actions
      } else {
        this.$swal({
          icon: "error",
          title: "ผิดพลาด",
          text: `ใส่ข้อมูลไม่ครบ กรุณากรอกข้อมูลให้ครบ `,
          buttonColor: "#f9a826",
        });
        // display an error message or highlight the invalid fields
      }
    },
    handleImageAdded: async function (
      file,
      Editor,
      cursorLocation,
      resetUploader
    ) {
      let formData = new FormData();
      formData.append("image", file);

      let { data } = await meeting.uploadPicture(formData);
      console.log(data.result);
      Editor.insertEmbed(cursorLocation, "image", data.result);
      resetUploader();
      // axios({
      //   url: this.base_url + "/file/fileupload",
      //   method: "POST",
      //   data: formData,
      //   headers: {'Authorization': 'Bearer ' + token.auth.userToken}
      // }).then(result => {
      //   let url = result.data.filePath; // Get url from response
      //   Editor.insertEmbed(cursorLocation, "image", url);
      //   resetUploader();
      // }).catch(err => {
      //   alert('yukleme basarisiz! ' + err)
      // });
    },
    clear() {
      this.form = {
        date: "",
        time_start: null,
        time_end: null,
        title: "",
        delegate: [],
        author: "",
        detail: "",
        files: [],
        booking: "",
        department: "",
        location:""
      };
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 8)];
      }
      return color;
    },
    remove(item) {
      const index = this.form.delegate.indexOf(item.id);
      if (index >= 0) this.form.delegate.splice(index, 1);
    },
    convertTime(dates, time) {
      let input = dates + " " + time;
      const date = new Date(input);
      let newDateString = date.toString();
      let date_object = new Date(newDateString);
      return date_object;
    },
    compareTimes(time1, time2) {
      let minutes1 =
        parseInt(time1.split(":")[0]) * 60 + parseInt(time1.split(":")[1]);
      let minutes2 =
        parseInt(time2.split(":")[0]) * 60 + parseInt(time2.split(":")[1]);

      return minutes1 > minutes2;
    },
    validateForm(form) {
      const requiredFields = ["date", "title", "author", "detail", "booking","location"];
      if (this.$store.getters.user.rule == "admin") {
        requiredFields.push("department");
      }
      const arrayFields = ["delegate"];
      const nullableFields = ["time_start", "time_end"];

      return (
        requiredFields.every((field) => form[field]) &&
        arrayFields.every((field) => Array.isArray(form[field])) &&
        nullableFields.every((field) => form[field] !== null)
      );
    },
    async getDepartment() {
      const { data } = await department.getdepartment();
      if (data.msg == "ok") {
        this.department = data.result;
      } else {
        alert(data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 150px;
  padding: 0 16px;
}
.vue_edeitor_height {
  height: 30vh;
  width: 100vw;
}
.form_data {
  position: relative;
  max-width: 1500px;
  max-height: 3000px;
  border-radius: 15px;
  background-color: white;
}
.header-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.exit-form {
  position: absolute;
  right: 10px;
  top: 5px;
}
.date-show {
  position: absolute;
  right: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.form-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.v-application p {
  margin-bottom: 0px;
}
.title_name {
  font-weight: bold;
}
.body-form {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}
.form_center {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
}
.buttom-form {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.rigth-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 100px;
    padding: 0 16px;
  }
  .form_data {
    position: relative;
    max-width: 1500px;
    max-height: 3000px;
    border-radius: 15px;
    background-color: white;
  }
  .rigth-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .header-card h2 {
    display: none;
  }
  .date-show {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .mobile_flex {
    display: flex;
    flex-direction: column;
  }
  .form-body p {
    font-size: 12px;
  }

  body,
  html {
    font-size: 12px;
  }
  .other_form {
    margin-top: 60px;
  }
}
</style>
