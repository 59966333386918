import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { VueEditor} from "vue2-editor";
import Vue2Filters from 'vue2-filters';
import dayjs from "dayjs";
import 'dayjs/locale/th'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import DatetimePicker from 'vuetify-datetime-picker'



// Vue 2 Editor
Vue.use(VueEditor);


//Vue 2 Filters
Vue.use(Vue2Filters);

// Day Js
const buddhistEra = require('dayjs/plugin/buddhistEra')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') 
const advancedFormat = require('dayjs/plugin/advancedFormat')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(buddhistEra)
dayjs.extend(relativeTime)
dayjs.locale('th')

dayjs.tz.guess()
// dayjs.tz.setDefault("Asia/Bangkok")

// Sweet Alert
Vue.use(VueSweetalert2);

// Datetime
Vue.use(DatetimePicker)

Vue.prototype.dayjs = dayjs


Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$store.dispatch('getEmployee');
    if (localStorage.getItem('isLoggedIn')) {
      this.$store.commit('login', JSON.parse(localStorage.getItem('user')));
    }
  },
  render: h => h(App)
}).$mount('#app')

