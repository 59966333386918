<template>
  <div>
    <v-app-bar color="white">
      <v-toolbar-title class="ml-1">{{ name }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <p class="mt-3">{{ time }}</p>
      <v-spacer></v-spacer>

      <v-btn
        @click="logout"
        icon
        class="mr-1"
        :x-small="$vuetify.breakpoint.smAndDown"
      >
        <v-icon color="#1888E6">mdi-logout</v-icon>
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            :x-small="$vuetify.breakpoint.smAndDown"
          >
            <v-icon color="#1888E6">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link v-if="rule == 'admin'">
            <v-list-item-title @click="dialogEmploy = true"
              ><v-icon color="primary">mdi-account-supervisor</v-icon>พนักงาน</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="dialog = true"
              ><v-icon color="primary">mdi-lock-reset</v-icon>เปลี่ยนรหัสผ่าน</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="aboueMe()"><v-icon color="primary">mdi-account-circle</v-icon>About Me</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Reset Password -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="primary lighten-2 text-white">
          <h3>เปลี่ยนรหัสผ่าน</h3>
        </v-card-title>

        <v-card-text>
          <br />
          <div class="password_form">
            <v-text-field
              v-model="password1"
              label="รหัสผ่านใหม่"
              outlined
              clearable
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              counter
            ></v-text-field>
            <v-text-field
              v-model="password2"
              label="กรอกรหัสผ่านใหม่อักครั้ง"
              outlined
              clearable
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              counter
            ></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="passwordChange()">
            เปลี่ยนรหัส
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EmployeeMG :dialog="dialogEmploy" @setDialog="setDialog"/>
  </div>
</template>

<script>
import auth from "@/controller/auth";
import EmployeeMG from '@/components/UserAdd.vue'

export default {
  data() {
    return {
      interval: null,
      time: null,
      name: process.env.VUE_APP_SYSTEMNAME,
      dialog: false,
      show1: false,
      show2: false,
      password1: "",
      password2: "",
      dialogEmploy:false,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    EmployeeMG
  },
  computed: {
    rule() {
      return this.$store.getters.user.rule;
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hourCycle: "h24",
      }).format();
    }, 1000);
  },
  methods: {
    setDialog(data){
      this.dialogEmploy = data;
    },
    async logout() {
      this.$swal({
        title: "Are you sure?",
        text: "ต้องการออกจากระบบหรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout");
          this.$router.push({ name: "login" });
        }
      });
    },
    aboueMe() {
      this.$swal(
        "สวัสดี",
        `You are login in <strong>${
          JSON.parse(localStorage.getItem("user")).name
        } </strong>`,
        "info"
      );
    },
    async passwordChange() {
      if (this.password1.length !== 0 && this.password2.length !== 0) {
        if (this.password1 == this.password2) {
          let formData = new FormData();
          formData.append("id", JSON.parse(localStorage.getItem("user")).id);
          formData.append("password", this.password2);
          let data  = await auth.updatePassword(formData);
          if (data.msg == "ok") {
            this.dialog = false;
            this.clear();
            this.$swal({
              icon: "success",
              title: "สำเร็จ",
              text: "เปลี่ยนรหัสผ่านสำเร็จ",
            });
          }
        } else {
          this.$swal({
            icon: "error",
            title: "ผิดพลาด",
            text: "รหัสผ่านไม่ตรงกัน",
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "ผิดพลาด",
          text: "กรอกข้อมูลไม่ครบ",
        });
      }
    },
    clear(){
      this.password1 = "";
      this.password2 = "";
    }
  },
};
</script>

<style lang="scss" scoped>
#app > div > div:nth-child(1) > header > div > div.v-toolbar__title {
  color: #1888e6;
  font-weight: bold;
}
#app > div > div:nth-child(1) > header > div > p {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  #app > div > div:nth-child(1) > header > div > div.v-toolbar__title {
    color: #1888e6;
    font-weight: bold;
    font-size: 14px;
  }
  #app > div > div:nth-child(1) > header > div > p {
    font-weight: bold;
    font-size: 12px;
  }
  .v-dialog > .v-card > .v-card__text {
    padding: 20px 24px 20px;
  }
  .text-white h3 {
    color: white;
  }
}
</style>
