<template>
  <div class="main">
      <Carlendar />
  </div>
</template>

<script>
import Carlendar from "../components/carlendar.vue";
export default {
  name: "HomeAdmin",
  components: {
    Carlendar,
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #e9e9e9;
  min-height: 100vh;
  padding: 30px;
}
</style>
