<template>
  <div class="main_app">

    <Carlendar />

   
    <div class="departmentDetail"  v-if="rule == 'admin'">
      <div class="icon-item" v-for="item in depart" :key="item.index">
        <v-icon :small="$vuetify.breakpoint.smAndDown" :color="item.color">mdi-circle</v-icon> <span>{{item.name}}</span>
      </div>
    </div>
    <div class="sp_btn">
      <v-fab-transition>
        <v-btn color="light-blue darken-3" dark  bottom right fab fixed x-small @click="reload()">
          <v-icon >mdi-reload</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import Carlendar from "../components/carlendar.vue";
import department from "@/controller/department";

export default {
  name: "Home",
  components: {
    Carlendar,
  },
  computed: {
    rule() {
      return this.$store.getters.user.rule;
    },
  },
  data() {
    return {
      depart:[],
    }
  },
  created() {
    this.getDepartmenrt();
  },
  methods: {
    reload(){
      window.location.reload();
    },
  async getDepartmenrt(){
      let {data} = await department.getdepartment();
      if (data.msg == 'ok') {
        this.depart = data.result;
      } else {
        alert(data.msg);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main_app {
  background-color: #e9e9e9;
  min-height: 100vh;
  padding: 30px;
}
.departmentDetail{
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media only screen and (max-width: 600px) {
  .main_app {
    background-color: #e9e9e9;
    min-height: 100vh;
    padding: 0px;
  }
  .departmentDetail{
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .icon-item span {
    font-size: 12px;
  }
}
</style>
